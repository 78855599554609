
import { Vue, Component  } from "vue-property-decorator";
import { EmpreendimentoService, PessoaService, TipoAditamentoContrato, UsuarioService } from "@/core/services/cadastros";
import { ContaCorrenteService, FormaPagamentoService, SituacaoReceitaParcelaService, TipoReceitaService, TipoServicoReceitaService, TipoAmortizacaoService, TipoContratoService} from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgReceita extends Vue {
  filter = new FiltroReceita();
    tiposAmortizacao = [];


    /* HEADERS */
    SelecionarFiltro(){
      this.$nextTick(() => {
        if (this.selecionarTodosFiltro) {
          this.filter.campos = [1,2,3,4,5,7,8,9,10,11,12,18]
        } else {
          this.filter.campos = this.headers.slice().map((x:any)=>x.id)
        }
      })
    }

     breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Parcela Detalhada', disabled: true, href: '/financeiro/painel/despesas'}
  ]

    validateNumeroContrato(value) {
      // Verifica se o valor é um número válido
      return !isNaN(value) && value !== null;
    }
    onInput(value) {
      // Remove caracteres não numéricos
      const sanitizedValue = value.replace(/[^\d]/g, ''); // Remove tudo que não for dígito
      this.filter.numeroContrato = sanitizedValue;
    }
    get selecionarTodosFiltro(){
      return this.filter.campos.length === this.headers.length
    }

    get SelecionarAlgunsFiltro(){

      return this.filter.campos.length > 0 && !this.selecionarTodosFiltro
    }

    get iconFiltro(){
      if (this.selecionarTodosFiltro) return 'mdi-close-box'
      if (this.SelecionarAlgunsFiltro) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textFiltro(){
      if (this.selecionarTodosFiltro) return 'Desmarcar'
      if (this.SelecionarAlgunsFiltro) return 'Selecionando...'
      return 'Selecionar todos'
    }

    /* EMPRESAS */
    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }
    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    }

    


    /* TIPO ADITAMENTO */
    SelecionarTipoAditamento(){
      this.$nextTick(() => {
        if (this.selecionarTodosTiposAditamento) {
          this.filter.tipoAditamentoId = []
        } else {
          this.filter.tipoAditamentoId = this.tipoAditamentos.slice().map((x:any)=>x.id)
        }
      })
    }

    get selecionarTodosTiposAditamento(){
      return this.filter.tipoAditamentoId.length === this.tipoAditamentos.length
    }

    get SelecionarAlgunsTiposAditamento(){
      return this.filter.tipoAditamentoId.length > 0 && !this.selecionarTodosTiposAditamento
    }

    get iconTiposAditamento(){
      if (this.selecionarTodosTiposAditamento) return 'mdi-close-box'
      if (this.SelecionarAlgunsTiposAditamento) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textTiposAditamento(){
      if (this.selecionarTodosTiposAditamento) return 'Desmarcar'
      if (this.SelecionarAlgunsTiposAditamento) return 'Selecionando...'
      return 'Selecionar todos'
    }


    /* SITUAÇÃO DA PARCELA */
    SelecionarSituacao(){
       this.$nextTick(() => {
          if (this.selecionarTodosSituacao) {
          this.filter.situacaoParcelaId = []
          } else {
            this.filter.situacaoParcelaId = this.situacoes.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosSituacao(){
      return this.filter.situacaoParcelaId.length === this.situacoes.length
    }

    get SelecionarAlgunsSituacao(){
      return this.filter.situacaoParcelaId.length > 0 && !this.selecionarTodosSituacao
    }

    get iconSituacao(){
      if (this.selecionarTodosSituacao) return 'mdi-close-box'
      if (this.SelecionarAlgunsSituacao) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textSituacao(){
      if (this.selecionarTodosSituacao) return 'Desmarcar'
      if (this.SelecionarAlgunsSituacao) return 'Selecionando...'
      return 'Selecionar todos'
    }


    /* TIPO DE RECEITA  */
    SelecionarTipoReceita() {
      this.$nextTick(() => {
        if (this.selecionarTodos) {
        this.filter.tipoReceitaId = []
        } else {
          this.filter.tipoReceitaId = this.tiposReceitas.slice().map((x:any)=>x.id)
        }
      })
    }

    get selecionarTodos(){
      return this.filter.tipoReceitaId.length === this.tiposReceitas.length
    }

    get SelecionarAlguns(){
      return this.filter.tipoReceitaId.length > 0 && !this.selecionarTodos
    }

    get icon(){
      if (this.selecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlguns) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get text(){
      if (this.selecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlguns) return 'Selecionando...'
      return 'Selecionar todos'
    }
     /* TIPO DE RECEITA  */


    /* TIPO SERVICO  */
    SelecionarTipoServico() {
      this.$nextTick(() => {
          if (this.selecionarTodosTipoServico) {
          this.filter.tipoServicoId = []
          } else {
            this.filter.tipoServicoId = this.tiposServicos.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosTipoServico(){
      return this.filter.tipoServicoId.length === this.tiposServicos.length;
    }

    get SelecionarAlgunsTipoServico(){
      return this.filter.tipoServicoId.length > 0 && !this.selecionarTodosTipoServico;
    }

    get iconTipoServico(){
      if (this.selecionarTodosTipoServico) return 'mdi-close-box'
      if (this.SelecionarAlgunsTipoServico) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textTipoServico(){
      if (this.selecionarTodosTipoServico) return 'Desmarcar'
      if (this.SelecionarAlgunsTipoServico) return 'Selecionando...'
      return 'Selecionar todos'
    }


    get iconEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
      return 'Selecionar todos'
    }

    /* CONTA BANCÁRIA  */
    SelecionarContaBancaria() {
      this.$nextTick(() => {
          if (this.selecionarTodasContaBancaria) {
          this.filter.selecionarContaBancariaId = []
          } else {
            this.filter.selecionarContaBancariaId = this.numeroConta.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodasContaBancaria(){
      return this.filter.selecionarContaBancariaId.length === this.numeroConta.length;
    }

    get SelecionarAlgumasContaBancaria(){
      return this.filter.selecionarContaBancariaId.length > 0 && !this.selecionarTodasContaBancaria;
    }

    get iconContaBancaria(){
      if (this.selecionarTodasContaBancaria) return 'mdi-close-box'
      if (this.SelecionarAlgumasContaBancaria) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textContaBancaria(){
      if (this.selecionarTodasContaBancaria) return 'Desmarcar'
      if (this.SelecionarAlgumasContaBancaria) return 'Selecionando...'
      return 'Selecionar todos'
    }


   /* EMPREENDIMENTO */
    SelecionarEmpreendimento() {
      this.$nextTick(() => {
          if (this.selecionarTodosEmpreendimentos) {
          this.filter.empreendimentoId = []
          } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosEmpreendimentos(){
      return this.filter.empreendimentoId.length === this.empreendimentos.length
    }
    get SelecionarAlgunsEmpreendimentos(){
      return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    pagamentoAutomatico= [
      {id:1, nome:"Sim", condicao:true},
      {id:2, nome:"Não", condicao:false},
    ]

    valid = true;

    numeroConta:any = [];
    pessoas:any = [];
    formaPagamento:any = [];
    empreendimentos: any = [];
    listaUsuarios:any = [];
    situacoes:any = [];
    tiposReceitas:any = [];
    empresas:any = [];
    tipoAditamentos:any = [];
    tiposServicos:any = [];
    listaTipoContratos = [];

    headers = [
      {id:1, nome:"Contrato"},
      {id:2, nome:"Nº da Parcela"},
      {id:3, nome:"Total de Parcela"},
      {id:4, nome:"Cliente"},
      {id:5, nome:"Tipo de Receita"},
      {id:6, nome:"Empresa"},
      {id:7, nome:"Empreendimento"},
      {id:8, nome:"Quadra"},
      {id:9, nome:"Lote"},
      {id:10, nome:"Vencimento"},
      {id:11, nome:"Data de Pagamento"},
      {id:12, nome:"Valor da Parcela"},
      {id:13, nome:"Situação da Parcela"},
      {id:14, nome:"Forma de Pagamento"},
      {id:15, nome:"Baixa Automática"},
      {id:16, nome:"Encargos"},
      {id:17, nome:"Descontos"},
      {id:18, nome:"Valor Pago"},
      {id:19, nome:"Valor Total Pago Parcela"},
      {id:20, nome:"Usuário da Baixa"},
      {id:21, nome:"Tipo de Aditamento"},
      {id:22, nome:"Corretor"},
      {id:23, nome:"Data de Vencimento Original"},
      {id:24, nome:"Observação"},
      {id:25, nome:"Tipo Serviço"},
      {id:26, nome:"CPF do Cliente"},
      {id:27, nome:"Nº documento"},
      {id:25, nome:"Tipo Serviço"},
      {id:26, nome:"Conta Bancária"},
      {id:27, nome:"CPF"},
      {id:28, nome:"Gateway"},
    ];

  mounted() {
    new TipoServicoReceitaService().ListarTudo().then(
      res => {
        this.tiposServicos = res.data.items;
      }
    )

    new ContaCorrenteService().ListarTudo().then(
      res => {
        this.numeroConta = res.data.items;
      }
    )

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => {
        this.empresas = res.data.items
      }
    )

    new TipoAditamentoContrato().ListarTudo().then(
      res => { this.tipoAditamentos = res.data.items
      }
    )

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items
      }
    );

    new FormaPagamentoService().ListarTudo().then(
      res => {
      this.formaPagamento = res.data.items
      }
    )

    new UsuarioService().ListarTudo().then(
      res => {
        this.listaUsuarios = res.data.items
      }
    )

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => {
        this.pessoas = res.data.items
      }
    )

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {
        this.situacoes = res.data.items
      }
    )

    new TipoReceitaService().ListarTudo().then(
      res => {
        this.tiposReceitas = res.data.items
      }
    )

     new TipoAmortizacaoService().ListarTudo().then(
      (res) => {
        this.tiposAmortizacao = res.data.items.filter(x => x.id != 3);
      })

      new TipoContratoService().ListarTudo().then(
        res => {
          this.listaTipoContratos = res.data.items;
        }
      )
  }

  Visualizar() {
    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }
    let routeLink = this.$router.resolve({
      name: "relReceitaContrato",
      query: {
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        tipoServicoId: this.filter.tipoServicoId ? this.filter.tipoServicoId.toString() : "",
        numeroContrato:this.filter.numeroContrato ? this.filter.numeroContrato : "",
        pagamentoAutomatico: this.filter.pagamentoAutomatico ? this.filter.pagamentoAutomatico : "",
        formaPagamento: this.filter.formaPagamento ? this.filter.formaPagamento : "",
        usuarioBaixa: this.filter.usuarioBaixa ? this.filter.usuarioBaixa : "",
        clienteId: this.filter.clienteId ? this.filter.clienteId : "",
        situacaoParcelaId: this.filter.situacaoParcelaId ? this.filter.situacaoParcelaId.toString() : "",
        contratoId:this.filter.contratoId ? this.filter.contratoId : "",
        tipoReceitaId: this.filter.tipoReceitaId ? this.filter.tipoReceitaId.toString() : "",
        dataVencimentoInicial:this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial: "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal: "",
        dataBaixaInicial:this.filter.dataBaixaInicial ? this.filter.dataBaixaInicial: "",
        dataBaixaFinal:this.filter.dataBaixaFinal ? this.filter.dataBaixaFinal: "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        tipoAditamentoId: this.filter.tipoAditamentoId ? this.filter.tipoAditamentoId.toString() : "",
        campos: this.filter.campos ? this.filter.campos.toString() : "",
        selecionarContaBancariaId: this.filter.selecionarContaBancariaId ? this.filter.selecionarContaBancariaId: "",
        tipoContrato: this.filter.TipoContratoId ? this.filter.TipoContratoId.toString() : "",
        tipoAmortizacao: this.filter.TipoTabelaId ? this.filter.TipoTabelaId.toString() : ""
      },
    });
    window.open(routeLink.href, "_blank");
  }
}

class FiltroReceita{
  tipoServicoId = [];
  selecionarContaBancariaId = [];
  empreendimentoId = [];
  numeroContrato:string = "";
  pagamentoAutomatico:string = "";
  formaPagamento:string = "";
  usuarioBaixa:string = "";
  clienteId:string = "";
  situacaoParcelaId:any = [];
  contratoId:string = "";
  tipoReceitaId:any = [];
  dataVencimentoInicial:string = "";
  dataVencimentoFinal:string = "";
  dataBaixaInicial:string = "";
  dataBaixaFinal:string = "";
  empresaId = [];
  tipoAditamentoId = [];
  campos = [1,2,3,4,5,7,8,9,10,11,12,18];
  TipoContratoId = "";
  TipoTabelaId = "";
}
